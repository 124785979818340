<template>
  <div class="bg-white">
    <div class="bg-white">
      <div
        class="
          max-w-7xl
          mx-auto
          py-12
          px-4
          text-center
          sm:px-6
          lg:px-8 lg:py-24
        "
      >
        <div class="space-y-8 sm:space-y-12">
          <div
            class="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl"
          >
            <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">
              AI-Campus Executive Team
            </h2>
            <!-- <p class="text-xl text-gray-500">Risus velit condimentum vitae tincidunt tincidunt. Mauris ridiculus fusce amet urna nunc. Ut nisl ornare diam in.</p> -->
          </div>
          <ul
            role="list"
            class="
              mx-auto
              grid grid-cols-2
              gap-x-4 gap-y-8
              sm:grid-cols-4
              md:gap-x-6
              lg:max-w-5xl lg:gap-x-8 lg:gap-y-12
              xl:grid-cols-6
            "
          >
            <li v-for="person in executiveTeam" :key="person.name">
              <div class="space-y-4">
                <img
                  class="
                    mx-auto
                    w-16
                    h-16
                    rounded-full
                    lg:w-20 lg:h-20
                    object-cover
                  "
                  :src="getImgUrl(person.imgUrl)"
                  alt=""
                />
                <div class="space-y-2">
                  <div class="text-xs font-medium lg:text-sm">
                    <h3>{{ person.name }}</h3>
                    <p class="text-blue-600">{{ person.role }}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Steering Committee -->
    <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
      <div class="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
        <div class="space-y-5 sm:space-y-4">
          <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">
            Meet the AI-Campus Steering Committee
          </h2>
          <!-- <p class="text-xl text-gray-500">Libero fames augue nisl porttitor nisi, quis. Id ac elit odio vitae elementum enim vitae ullamcorper suspendisse. Vivamus fringilla.</p> -->
        </div>
        <div class="lg:col-span-2">
          <ul
            role="list"
            class="
              space-y-12
              sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0
              lg:gap-x-8
            "
          >
            <li v-for="person in steeringCommitee" :key="person.name">
              <div class="flex items-center space-x-4 lg:space-x-6">
                <img
                  class="
                    my-auto
                    w-16
                    h-16
                    rounded-full
                    lg:w-20 lg:h-20
                    object-cover
                  "
                  :src="getImgUrl(person.imgUrl)"
                  alt=""
                />
                <div class="font-medium text-lg leading-6 space-y-1">
                  <h3>{{ person.name }}</h3>
                  <p class="text-blue-600">{{ person.role }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// AI-Campus executive team:
const executiveTeam = [
  {
    name: "Xiuzhen Huang",
    role: "Founding Director",
    imgUrl: "img/xhuang.jpg",
  },
  {
    name: "Karl Walker",
    role: "Executive Director",
    imgUrl: "img/kwalker.png",
  },
  {
    name: "Jake Qualls",
    role: "Associate Executive Director",
    imgUrl: "img/jqualls.jpg",
  },
  { name: "Yuanfang Guan", role: "Chief Scientist", imgUrl: "img/yguan.jpg" },
  {
    name: "Jason Moore",
    role: "Director for Media and Communications",
    imgUrl: "img/jmoore.jpeg",
  },
  {
    name: "Xiaoqian Jiang",
    role: "Director for Infrastructure",
    imgUrl: "img/xjiang.png",
  },
  { name: "Jason Causey", role: "Head Coach", imgUrl: "img/jcausey.jpg" },
  { name: "Wei Dong", role: "Associate Head Coach", imgUrl: "img/wdong.png" },
  {
    name: "Jennifer Fowler",
    role: "Director for Partner Engagement",
    imgUrl: "img/jfowler.jpeg",
  },
  {
    name: "Emily Bellis",
    role: "Director for Student Engagement",
    imgUrl: "img/ebellis.jpg",
  },
];
const steeringCommitee = [
  { name: "Xiuzhen Huang", role: "Co-Chair", imgUrl: "img/xhuang.jpg" },
  { name: "Aidong Zhang", role: "Co-Chair", imgUrl: "img/azhang.jpg" },
  { name: "Karl Walker", imgUrl: "img/kwalker.png" },
  { name: "Jennifer Fowler", imgUrl: "img/jfowler.jpeg" },
  { name: "Xiaoqian Jiang", imgUrl: "img/xjiang.png" },
  { name: "Jason Moore", imgUrl: "img/jmoore.jpeg" },
  { name: "Scott Williams", imgUrl: "img/swilliams.png" },
  { name: "Yuanfang Guan", imgUrl: "img/yguan.jpg" },
  { name: "Steve Jennings", imgUrl: "img/sjennings.png" },
];

import { getImgUrl } from "../helper";

export default {
  methods: {
    getImgUrl,
  },
  setup() {
    return {
      steeringCommitee,
      executiveTeam,
    };
  },
};
</script>
